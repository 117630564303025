import { ISettings } from "./interfaces/ISettings";



export class ProjectSettings {

    private static readonly NODE_ENV = process.env.REACT_APP_ENV as string
    private static readonly INSTANCE_NAME = process.env.REACT_APP_INSTANCE as string
    private static APP_VERSION = process.env.VITE_APP_VERSION
    private static readonly SETTINGS: ISettings = require(`./${process.env.REACT_APP_INSTANCE}_settings.ts`).default

    private static readonly MIRROR =
        Object.keys(this.SETTINGS.MIRRORS).find((item) => {
            return item === window.location.host.split("www.").join("");
        });

    static node_env() {
        return this.NODE_ENV
    }

    static app_version() {
        return this.APP_VERSION;
    }
    static instance_name() {
        return this.INSTANCE_NAME
    }

    static settings() {
        return this.SETTINGS
    }

    static seo() {
        return this.SETTINGS.SEO
    }

    static access() {
        return this.SETTINGS.ACCESS
    }

    static other() {
        return this.SETTINGS.OTHER
    }

    static theme() {
        return this.SETTINGS.THEME
    }

    static variant() {
        return this.SETTINGS.OTHER.VARIANT
    }

    static currency() {
        return this.SETTINGS.OTHER.CURRENCY.KEY
    }

    static services() {
        return this.NODE_ENV === "development" ? this.SETTINGS.SERVICES_DEV : this.SETTINGS.SERVICES_PROD
    }

    static api() {
        return this.NODE_ENV === "development"
            ? this.SETTINGS.API_DEV_URL
            : this.MIRROR
                ? this.SETTINGS.MIRRORS[`${this.MIRROR}`].API_PROD_URL
                : this.SETTINGS.API_PROD_URL;
    }
}