import { createStore } from "effector";
import { useStore } from "effector-react";
import { IPopupsStore } from "./popup.types";
import {
  checkIfThereAreUnviewedPopupsFx,
  checkPopupViewedFx,
  getPopupsFx,
  getUnviewedPopupsFx,
  openPopupModalEvent,
} from "./popup.actions";

export const $popups = createStore<IPopupsStore>({ count: 0, rows: [] })
  .on(getPopupsFx.done, (state, { params, result }) =>
    params.page !== undefined && params.page > 1
      ? {
          count: state.count,
          rows: [...state.rows, ...result.rows],
        }
      : result
  )
  .on(getUnviewedPopupsFx.doneData, (_, payload) => payload)
  .on(checkPopupViewedFx.doneData, (state, payload) => ({
    ...state,
    rows: state.rows.map((popup) =>
      popup.id === payload.id ? payload : popup
    ),
  }));

export const $isOpenPopupModal = createStore<boolean>(false)
  .on(openPopupModalEvent, (_, payload) => payload)
  .on(getUnviewedPopupsFx.doneData, (_, payload) => {
    return !!payload.rows.length;
  });

$isOpenPopupModal.watch((v) => {
  console.log("$isOpenPopupModal = ", v);
});

export const $isUnviewedPopups = createStore<boolean>(false)
  .on(checkIfThereAreUnviewedPopupsFx.doneData, (_, payload) => payload)
  .on(
    checkPopupViewedFx.doneData,
    () =>
      $popups.getState().rows.filter((popup) => popup.isViewed === false)
        .length > 0
  );

export const usePopupsStore = () => useStore($popups);
export const useIsUnviewedPopupsStore = () => useStore($isUnviewedPopups);
