import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import { ProjectSettings } from "config";

const defaultLanguage =
  Object.entries(ProjectSettings.other().LANGUAGES).find(
    ([, lang]) => lang.DEFAULT
  )?.[1].KEY || "fr";
i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: defaultLanguage,
    react: {
      useSuspense: false,
    },
    debug: false,
    detection: {
      order: ["queryString", "cookie"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then((r) => {
    // console.log("initialize language success", r);
  });

export default i18n;
