import React, { useEffect, useMemo } from "react";
import { YMInitializer } from "react-yandex-metrika";
import Router from "./routes";
import i18n from "./i18n";
import { getTheme } from "./theme";
import { useTypedDispatch, useTypedSelector } from "./hooks/useTypedRedux";
import { setTheme } from "./redux/reducers/appSlice";
import { ThemeProvider } from "styled-components";
import { selectThemeType } from "./redux/selectors/appSelector";
import { ProjectSettings } from "./config";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { useCurrentLanguage } from "./fsd/shared/lib";

const services = ProjectSettings.services();

function App() {
  const settings = ProjectSettings.settings();
  const mirror = Object.keys(settings.MIRRORS).find(
    (item) => item === window.location.host
  );

  const lang = useCurrentLanguage();

  const dispatch = useTypedDispatch();
  const theme_type = useTypedSelector(selectThemeType);

  const languages_list = settings.OTHER.LANGUAGES;

  const default_language: string = useMemo(() => {
    if (languages_list) {
      const languages = Object.values(settings.OTHER.LANGUAGES).filter(
        (language) => language.VISIBLE && language.DEFAULT
      );
      return languages[0].KEY ?? "en";
    } else {
      return "en";
    }
  }, [languages_list]);

  const site = settings.SEO.SITE_NAME;

  useEffect(() => {
    dispatch(
      setTheme(
        localStorage.getItem(`${site}_theme_mode`) || settings.THEME.DEFAULT
      )
    );
    i18n.changeLanguage(
      localStorage.getItem(`${site}_language`) || default_language
    );
  }, []);

  useEffect(() => {
    if (lang === "ar") {
      document.body.style.direction = "rtl";
    } else document.body.style.direction = "ltr";
  }, [lang]);

  document.body.dir = i18n.dir();
  // @ts-ignore
  return (
    <>
      {/*// @ts-ignore*/}
      <ThemeProvider theme={getTheme(theme_type)}>
        <Router />
        {
          // @ts-ignore
          <YMInitializer
            accounts={[+(services?.YM?.KEY ?? "")]}
            options={{
              clickmap: true,
              trackLinks: true,
              accurateTrackBounce: true,
              webvisor: true,
            }}
          />
        }
      </ThemeProvider>
    </>
  );
}

export default App;
