import React, {
  lazy,
  Suspense,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { useGetUserDataQuery } from "redux/api/userApi";
import "../fsd/processes";
import { useTypedDispatch, useTypedSelector } from "hooks/useTypedRedux";
import {
  updateAlert,
  isMobileTrue,
  updateModalPopup,
} from "redux/reducers/appSlice";
import { useGetLanguagesQuery, useGetStaticPagesQuery } from "redux/api/appApi";
import { TawkService } from "services/TawkService";
import CustomAlert from "ui/CustomAlert";
import { io } from "socket.io-client";
import { SocketProvider } from "services/SocketService";
import Loader from "components/Loader";
import { useTranslation } from "react-i18next";
import { updateBalance } from "redux/reducers/userSlice";
import { IMessage } from "redux/types/appTypes";
import FacebookService from "services/FacebookService";
import { useGetAllPromotionsMutation } from "redux/api/advertisementApi";
import { IPromotion } from "redux/types/advertisementTypes";
import { selectUserId } from "redux/selectors/appSelector";
import GoogleService from "services/GoogleService";
import {
  setDescription,
  setFavicon,
  setManifest,
  setTitle,
} from "helpers/setHead";
import { ProjectSettings } from "config";
import PopupModalReflect from "../fsd/entities/popup/ui/popup-modal/popup-modal";
import { useLocation } from "react-router-dom";

const RegisterModal = lazy(() => import("../components/modal/RegisterModal"));
const LoginModal = lazy(() => import("../components/modal/LoginModal"));
const RecoveryPasswordModal = lazy(
  () => import("../components/modal/RecoveryPasswordModal")
);

const PopupModal = lazy(() => import("../components/modal/PopupModal"));

const CheckEmailModal = lazy(
  () => import("../components/modal/CheckEmailModal")
);

const MobileApp = lazy(() => import("./mobile-app"));
const DesktopApp = lazy(() => import("./desktop-app"));

const Router = () => {
  const { i18n } = useTranslation();
  const [socket, setSocket] = useState<any>(null);

  const dispatch = useTypedDispatch();

  const userId = useTypedSelector(selectUserId);

  const access = ProjectSettings.access();
  const settings = ProjectSettings.settings();
  const BASE_URL = ProjectSettings.api();
  const SERVICES = ProjectSettings.services();

  //const {} = useGetVerificationStatusQuery();
  const {} = useGetStaticPagesQuery();
  const {} = useGetLanguagesQuery();
  const { isLoading } = useGetUserDataQuery();
  const [getAllPromotions] = useGetAllPromotionsMutation();

  const [init, setInit] = useState<boolean>(false);
  const [isMobileDevice, setIsMobileDevice] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    setInit(false);
    if (typeof window.orientation !== "undefined") {
      TawkService.initialize();
      setIsMobileDevice(true);
      dispatch(isMobileTrue());
    }

    if (!isLoading) {
      setInit(true);
    }
  });

  const queryParams = new URLSearchParams(window.location.search);
  const referralCodeLink = queryParams.get("ref");
  useEffect(() => {
    if (referralCodeLink) {
      sessionStorage.setItem(
        "lettobet_register_referral_field",
        referralCodeLink
      );
      sessionStorage.setItem(
        "lettobet_register_referral_field_was_got_from_link",
        "yes"
      );
    }
  }, [referralCodeLink]);

  // Google metrika
  useLayoutEffect(() => {
    if (SERVICES.GA !== undefined) {
      GoogleService(SERVICES.GA.ID);
    }
  }, []);

  // Facebook metrika
  useLayoutEffect(() => {
    FacebookService();
  }, []);

  // Set favicon, title, description
  useEffect(() => {
    setFavicon(settings.SEO.LOGO_AND_FAVICON);
    setManifest(settings.SEO.LOGO_AND_FAVICON);
    setTitle(settings.SEO.TITLE);
    setDescription(settings.SEO.DESCRIPTION);
  }, []);

  /*    // Set manifest
    useLayoutEffect(() => {
        const manifest = settings.SEO.MANIFEST
        if (manifest) {
            setManifest(manifest)
        }
    }, [])*/

  // Tawk service
  useLayoutEffect(() => {
    if (SERVICES.TAWK !== undefined) {
      let s1 = document.createElement("script");
      let s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      s1.src = `https://embed.tawk.to/${SERVICES.TAWK.ID}/${SERVICES.TAWK.PROPERTY_ID}`;
      s1.charset = "UTF-8";
      s1.id = "script_tawk_service";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode?.insertBefore(s1, s0);
    }
  }, []);

  const POPUP = settings.OTHER.POPUP;

  // popup
  useLayoutEffect(() => {
    if (POPUP && !POPUP.DISABLED) {
      if (isMobileDevice && POPUP.MOBILE) {
        getAllPromotions({ lang: POPUP.LANGUAGE }).then((pld: any) => {
          // if (pld.data.find((item: IPromotion) => item.id === POPUP.ID)) {
          if (
            pld.data.find(
              (item: IPromotion) =>
                item.id ===
                (ProjectSettings.node_env() === "development"
                  ? POPUP.DEV_ID
                  : POPUP.PROD_ID)
            )
          ) {
            dispatch(updateModalPopup());
          }
        });
      } else if (POPUP.DESKTOP) {
        getAllPromotions({ lang: POPUP.LANGUAGE }).then((pld: any) => {
          if (
            pld.data.find(
              (item: IPromotion) =>
                item.id ===
                (ProjectSettings.node_env() === "development"
                  ? POPUP.DEV_ID
                  : POPUP.PROD_ID)
            )
          ) {
            dispatch(updateModalPopup());
          }
        });
      }
    }
  }, [isMobileDevice]);

  // @ts-ignore
  useEffect(() => {
    const newSocket = io(`${BASE_URL}`, {
      transports: ["websocket"],
      auth: { userId: userId ?? +new Date() },
    });
    newSocket.on("balance", (pld) => {
      console.log("balance socket =>", pld.balance);
      dispatch(
        updateBalance({ balance: pld.balance, bonusBalance: pld.bonusBalance })
      );
    });
    newSocket.on("notification", (pld) => {
      // console.log("notification socket =>", pld)
      dispatch(
        updateAlert({
          isOpen: true,
          status: pld.type,
          message: pld.content.find(
            (item: IMessage) => item.lang === i18n.language
          ).message,
        })
      );
    });
    setSocket(newSocket);
    return () => newSocket.close();
  }, [setSocket, userId]);

  return (
    <>
      <Suspense fallback={<></>}>
        <CheckEmailModal />
        <RegisterModal />
        <LoginModal />
        {access.RECOVERY_PASSWORD.VISIBLE && (
          <>
            <RecoveryPasswordModal />
          </>
        )}
        {POPUP && !POPUP?.DISABLED && (
          <PopupModal
            id={
              ProjectSettings.node_env() === "development"
                ? POPUP.DEV_ID
                : POPUP.PROD_ID
            }
          />
        )}
      </Suspense>
      <CustomAlert isDesktop={!isMobileDevice} />
      <Loader />
      <Suspense fallback={<Loader />}>
        {init && (
          <SocketProvider socket={socket}>
            {isMobileDevice ? <MobileApp /> : <DesktopApp />}
          </SocketProvider>
        )}
      </Suspense>
    </>
  );
};

export default Router;
